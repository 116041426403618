/* Add project-specfic styles */

html {
  --veoc-status-green: #6eaa2c;
  --veoc-status-grey: rgba(0, 0, 0, 0.54);
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #c6c5c5;
}
::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
}

/* Grid styling */

.ReactTable {
  padding: 0;
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 0 !important;
}

.ReactTable .rt-thead.-header {
  box-shadow: unset;
}

/* Row highlighting */
.ReactTable .rt-table .rt-tbody .rt-tr-group:hover {
  background: rgba(0, 0, 0, 0.05);
}

.no-overflow-y {
  overflow-y: unset;
}

.dialog-content-padding-adjust {
  padding: 8px 16px;
}

input[type='file'] {
  display: none;
  visibility: hidden;
  width: 0;
  height: 0;
}

.form-margin {
  margin: 0 10px;
}

.body-cell {
  height: 43px;
  padding-left: 18px !important;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  /* color: var(--primary-text-color)  !important; */
  cursor: pointer !important;

  border-bottom: 1px solid #e0e0e0;
}

.body-header {
  padding-left: 18px;
  font-size: 16px !important;
  background: #f5f5f5 !important;
  font-weight: 500 !important;
  min-height: 42px !important;
  line-height: 0;
  box-sizing: border-box;
  box-shadow: 0 -1px 0 #e0e0e0 inset !important;
}

/* For the Forms */

.section-title {
  opacity: 0.87;
  font-size: 14px;
  height: 34px;
  font-weight: bold;
  text-align: left;
  margin: 0 6px;
  text-transform: uppercase;
  color: var(--pcl-primary-section-title-color);
}

.form-container {
  padding: 16px;
}

.form-margin {
  margin-left: 6px;
  margin-right: 6px;
}

.form-margin-16 {
  margin-left: 16px;
  margin-right: 16px;
}

.form-margin-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.margin-16 {
  margin: 16px;
}

.margin-10 {
  margin: 10px;
}

.padding-12 {
  padding: 12px;
}

.horiz-sep-bottom-1 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

a {
  text-decoration: none;
}

/* Dropdown Menu Placeholder */
.placeholder {
  color: rgba(0, 0, 0, 0.4);
}

/* Indicators for Status */
.status-indicator {
  height: 8px;
  width: 8px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0.5em;
  border-radius: 4px;
}

.status-indicator.green {
  background-color: var(--veoc-status-green);
}

.status-indicator.grey {
  background-color: var(--veoc-status-grey);
}

.dialog {
  min-width: 288px;
  min-height: 288px;
  max-height: 432px;
}

.grid-layout-2r2c {
  display: grid;
  grid-template-columns: 24px 1fr;
  grid-template-rows: 24px 1fr;
  grid-auto-flow: column;
  column-gap: 0;
  row-gap: 0;
}
