div#bottombar .header-container {
  height: 68px !important;
}

.MuiTab-fullWidth {
  background: #4c7bfc;
  color: rgba(255, 255, 255, 0.6);
}

.MuiTab-fullWidth,
.Mui-selected {
  background: transparent !important;
  color: #2961fd !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  height: 50px;
}

.MuiTab-root {
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
  height: 50px;
  letter-spacing: 0.02857em;
  line-height: 1.75;
  margin-left: auto;
  margin-right: auto;
  max-width: 160px;
  min-height: 50px;
  overflow: hidden;
  padding: 6px 12px;
  position: relative !important;
  text-align: center;
  white-space: normal;
}

.MuiTab-root.Mui-selected {
  height: 50px !important;
  margin-left: auto !important;
  margin-right: auto !important;
  min-height: 50px !important;
}

.MuiTab-textColorInherit {
  color: inherit;
  opacity: 0.7;
}

.MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
}

.MuiTab-textColorPrimary {
  color: #cccccc;
}

.MuiTab-wrapper {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.MuiTabs-centered {
  justify-content: center;
}

.MuiTabs-fixed {
  overflow-x: hidden;
  width: 100%;
}

.MuiTabs-flexContainer {
  display: flex;
}

.MuiTabs-root {
  position: relative;
}

.MuiTabs-scroller {
  display: inline-block;
  flex: 1 1 auto;
  position: relative;
  white-space: nowrap;
}

.app-logo {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
}

.app-title {
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
}
